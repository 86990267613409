@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@layer {
    .p-splitbutton {
        .p-splitbutton-defaultbutton {
            border-radius: 10px 0 0 10px !important;
            
            &.p-button-small {
                padding: 0.4375rem 0.875rem;
            }
        }
        
        .p-splitbutton-menubutton {
            border-radius: 0 10px 10px 0 !important;
        }

        .p-tieredmenu {
            width: 100%;
        }
    }
  }