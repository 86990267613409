/* Regular */
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.woff2") format("woff2"),
       url("Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.woff2") format("woff2"),
       url("Inter-Italic.woff") format("woff");
}

/* Medium */
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.woff2") format("woff2"),
       url("Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.woff2") format("woff2"),
       url("Inter-MediumItalic.woff") format("woff");
}

/* SemiBold */
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.woff2") format("woff2"),
       url("Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.woff2") format("woff2"),
       url("Inter-SemiBoldItalic.woff") format("woff");
}

/* Bold */
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.woff2") format("woff2"),
       url("Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.woff2") format("woff2"),
       url("Inter-BoldItalic.woff") format("woff");
}